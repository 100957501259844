import { Link } from "react-router-dom";

function MainButton() {
    return (
        <>
            <Link onClick={() => {
                console.log('click');
            }} className="main-button">
                    Get in touch
            </Link>
        </>
    )
}

export default MainButton;