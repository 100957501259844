import './soon.scss'
import { Link } from 'react-router-dom';

function Soon() {
  return (
   <div className='container'>
    <p className='title-soon'>
      SOON
    </p>
      
    <Link to='/'>Home</Link>

   </div>
  );
}

export default Soon;