import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './header.scss';

import logo from './../../assets/images/header/logo.svg'

import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const headerClass = `header fixed ${isScrolled ? 'is-scrolled' : ''}`;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false); 
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  function toggleFormBox() {
    const formBox = document.querySelector('.form__box');
  
    if (formBox) {
      formBox.classList.toggle('active');
    }
  }

  return (
   <div className='header'>
    <header>
      <div className='container header__container'>
        <div className='logo'>
          <Link to="/">
            <img src={logo}/>
          </Link>
        </div>
        <div className='nav'>
            <ul>
              <Link to="/home">
                Home<span style={{color: 'red'}}>*</span>
              </Link>
              <Link to="/soon">
                Calculator<span style={{color: 'red'}}>*</span>
              </Link>
              <Link to="/soon">
                Brief<span style={{color: 'red'}}>*</span>
              </Link>
              <Link to="/soon">
                3D Gallery<span style={{color: 'red'}}>*</span>
              </Link>
            </ul>
        </div>
      </div>
    </header>
   </div>
  );
}

export default Header;
