import './notfound.scss'
import { Link } from 'react-router-dom';

function NotFound() {
  return (
   <>

    <svg xmlns="http://www.w3.org/2000/svg" width="468" height="176" viewBox="0 0 468 176" fill="none">
      <path d="M468 116.642V136.928H446.748V172.669H423.082V136.928H333.246V94.6659C353.531 87.0991 370.356 75.3463 383.718 59.4077C397.242 43.469 405.131 24.4714 407.385 2.41485H431.051C425.094 55.0608 394.586 92.3315 339.525 114.227V120.506L355.705 116.642H423.082V59.6492H446.748V116.642H468Z" fill="url(#paint0_linear_23_1087)"/>
      <path d="M231.912 0C255.418 0 273.691 8.13033 286.732 24.391C299.773 40.4907 306.293 61.5007 306.293 87.4212C306.293 113.503 299.773 134.674 286.732 150.934C273.691 167.034 255.418 175.084 231.912 175.084C208.246 175.084 189.892 167.034 176.852 150.934C163.811 134.674 157.29 113.503 157.29 87.4212C157.29 61.5007 163.811 40.4907 176.852 24.391C189.892 8.13033 208.246 0 231.912 0ZM231.912 20.2856C215.491 20.2856 202.853 26.4035 193.998 38.6392C185.304 50.7139 180.957 66.9746 180.957 87.4212C180.957 101.75 183.13 114.147 187.477 124.611L264.756 32.8433C256.062 24.4715 245.114 20.2856 231.912 20.2856ZM231.912 154.798C248.334 154.798 260.892 148.761 269.586 136.686C278.279 124.45 282.626 108.029 282.626 87.4212C282.626 72.7705 280.453 60.2932 276.106 49.9895L198.828 142.241C207.521 150.612 218.55 154.798 231.912 154.798Z" fill="url(#paint1_linear_23_1087)"/>
      <path d="M135.237 116.642V136.928H113.985V172.669H90.3188V136.928H0.482666V94.6659C20.7682 87.0991 37.5924 75.3463 50.9551 59.4077C64.4788 43.469 72.3677 24.4714 74.6216 2.41485H98.2881C92.3313 55.0608 61.8224 92.3315 6.76154 114.227V120.506L22.9417 116.642H90.3188V59.6492H113.985V116.642H135.237Z" fill="url(#paint2_linear_23_1087)"/>
      <defs>
        <linearGradient id="paint0_linear_23_1087" x1="0.482665" y1="64.3406" x2="467.984" y2="61.9091" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFB118"/>
          <stop offset="0.528817" stop-color="#00BB44"/>
          <stop offset="1" stop-color="#017CF8"/>
        </linearGradient>
        <linearGradient id="paint1_linear_23_1087" x1="0.482665" y1="64.3406" x2="467.984" y2="61.9091" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFB118"/>
          <stop offset="0.528817" stop-color="#00BB44"/>
          <stop offset="1" stop-color="#017CF8"/>
        </linearGradient>
        <linearGradient id="paint2_linear_23_1087" x1="0.482665" y1="64.3406" x2="467.984" y2="61.9091" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFB118"/>
          <stop offset="0.528817" stop-color="#00BB44"/>
          <stop offset="1" stop-color="#017CF8"/>
        </linearGradient>
      </defs>
    </svg>

    <Link to='/'>Home</Link>

   </>
  );
}

export default NotFound;