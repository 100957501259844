import './footer.scss';

function Footer() {
  return (
    <footer className='footer flex'>
      Footer
    </footer>
  );
}

export default Footer;
