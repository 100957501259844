import "./home.css";
import { motion, useMotionValue, useAnimation } from 'framer-motion';
import { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from 'react-spring';
import work1 from './../../assets/images/works/work1.png'
import works from "../../utils/works";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation'; 

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import MainButton from "../../components/main-button/button";


function Home() {

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };



  const handleMouseMove = (e) => {
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    setSpringProps({
      xy: [
        (mouseX / window.innerWidth) * 23,
        (mouseY / window.innerHeight) * 30,
      ],
    });
  };

  const [{ xy }, setSpringProps] = useSpring(() => ({ xy: [0, 0] }));
 
  console.log(works);

  return (
    <main className="">
        <section className="pt-[70px]">
            <div className="container flex items-top first-letter">
                <div className="w-1/2">
                  <h1 className="text-white">
                    <span className="gradient-text">Modern</span> and <span className="gradient-text">stylish</span> solutions for your business
                  </h1>
                </div>
                <div className="flex flex-col gap-[40px] items-start w-1/2"> 
                  <p className="text-white">
                    We have quality for any budget. We will create the ideal product for your needs, regardless of the size of the project.
                  </p>
                  <MainButton />
                </div>
            </div>   
            <div className="container flex gap-[10px]">
              <div className="w-1/3">
                <motion.div 
                  className="home-card h-full relative overflow-hidden"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                >
                  <h3>
                    Our clients say about us:
                  </h3>
                  <div className=" overflow-hidden">
                    <Swiper
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        el: ".swiper-pagination",
                        type: 'progressbar',
                        
                      }}
                      navigation={{
                        prevEl: '.custom-prev-button',
                        nextEl: '.custom-next-button',
                      }}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        Crafting user-centric content often feels like navigating a maze, but thanks to [название компании],
                      </SwiperSlide>
                      <SwiperSlide>
                        Crafting user-centric content often feels like navigating a maze, but thanks to [название компании],
                      </SwiperSlide>
                      <SwiperSlide>Slide 3</SwiperSlide>
                      <SwiperSlide>Slide 4</SwiperSlide>
                      <SwiperSlide>Slide 5</SwiperSlide>
                      <SwiperSlide>Slide 6</SwiperSlide>
                      <SwiperSlide>Slide 7</SwiperSlide>
                      <SwiperSlide>Slide 8</SwiperSlide>
                      <SwiperSlide>Slide 9</SwiperSlide>
                    </Swiper>
                  </div>
                  <div className="swiper-pagination"></div>
                  <div className="flex gap-[10px] justify-end mt-8">
                    <div className="custom-prev-button p-[10px] bg-black">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <path d="M2.26137 9.88477e-08L2.26137 20.9985H0L9.17869e-07 0L2.26137 9.88477e-08Z" fill="#EFF8FA"/>
                        <path d="M20.9996 20.9985H0.00112214L0.00112234 18.7371L20.9996 18.7371V20.9985Z" fill="#EFF8FA"/>
                        <path d="M20.1871 2.08972L2.27616 20.0006L0.677133 18.4016L18.588 0.490691L20.1871 2.08972Z" fill="#EFF8FA"/>
                      </svg>
                    </div>
                    <div className="custom-next-button">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <path d="M2.26137 9.88477e-08L2.26137 20.9985H0L9.17869e-07 0L2.26137 9.88477e-08Z" fill="#EFF8FA"/>
                        <path d="M20.9996 20.9985H0.00112214L0.00112234 18.7371L20.9996 18.7371V20.9985Z" fill="#EFF8FA"/>
                        <path d="M20.1871 2.08972L2.27616 20.0006L0.677133 18.4016L18.588 0.490691L20.1871 2.08972Z" fill="#EFF8FA"/>
                      </svg>
                    </div>
                  </div>
                </motion.div>
              </div>
              <div className="w-2/3 flex flex-col gap-[10px]">
                <motion.div 
                  className="home-card"
                  initial={{ scale: 0 }} 
                  animate={{ scale: 1, transition: { delay: 0.5 } }}                        
                >
                    <h3>
                      Tariffs we work at
                    </h3>
                    <p className="text-[16px]">
                      Choose a development plan to suit your budget
                    </p>
                </motion.div>
                <div className="flex gap-[10px] h-full">
                  <motion.div 
                    className="home-card w-1/2"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1, transition: { delay: 0.7 } }}                        
                  >
                    <h3>
                      Сooperation
                    </h3>
                    <p className="text-[16px]">
                      Choose a development plan to suit your budget
                    </p>
                  </motion.div>
                  <motion.div 
                    className="home-card w-1/2"
                    initial={{ scale: 0 }} 
                    animate={{ scale: 1, transition: { delay: 1} }}                        
                  >
                    <h3>
                      Calculator
                    </h3>
                  </motion.div>
                </div>
              </div>
            </div>                                                                                                                                                                                                                                                                                             
        </section>
        <section>

        </section>
        <section className="pt-24">
          <div className="container">
            <div className="flex justify-center flex-col items-center">
              <h2 className="text-white">Our works</h2>
              <p className="text-green underline">View in 3D</p>
            </div>  
            <div>
              <div
                  className="magnetic-container"
                  onMouseMove={handleMouseMove}
                  // onMouseLeave={handleMouseMove}
                 
              >
                <animated.div
                  className="flex gap-[10px] text-white"
                  style={{
                    transform: xy.interpolate((x, y) => `translate3d(${x}%, ${y}%, 0)`),
                    // transition: isHovered ? 'none' : 'transform 0.5s ease-out',                  
                  }}
                >
                  <div className="work-img">
                    <img src={work1} />
                  </div>
                
                  <div>
                    <h3>
                      Title 1
                    </h3>
                    <p>
                      Deskr 1
                    </p>
                    <a className="text-green" href="#">Link 1</a>
                  </div>
                </animated.div>
              </div>
            </div>
          </div>
        </section>
    </main> 
  );
}

export default Home;
