const works = 
[
    {
        id: '1',
        title: 'title 1',
        img: 'img url',
        date: 'from-to',
        deskription: 'text 1',
    },
    {
        id: '2',
        title: 'title 1',
        img: 'img url',
        date: 'from-to',
        deskription: 'text 1',
    },
]

export default works;