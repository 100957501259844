import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.scss";
import "./assets/fonts/fonts.css"

import Footer from "./container/footer/Footer";
import Home from "./pages/home/Home";
import Header from "./container/header/Header"
import Admin from "./pages/admin/admin";
import NotFound from "./pages/404/NotFound";
import Soon from "./pages/soon/soon";
import ComingSoon from "./pages/coming-soon/ComingSoon";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/home" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/soon" element={<Soon />} />
        <Route
          path="/*"
          element={
            <>
              <Navigate to="/not-found" />
              <NotFound />
            </>
          }
      />
      </Routes>
      <Footer />
    </>
  );
}


export default App;
