import './admin.css';
import { useEffect, useState, useRef } from 'react';
import { BookOpenIcon, BookmarkSlashIcon, BriefcaseIcon, Cog6ToothIcon, CogIcon, CpuChipIcon, HomeIcon, LifebuoyIcon, LightBulbIcon, SunIcon } from '@heroicons/react/24/solid'
import logo from './../../assets/images/header/logo.svg'

function Admin() {

  useEffect(() => {
    const header = document.querySelector('.header');
    const footer = document.querySelector('.footer');
    header.classList.add('hidden');
    footer.classList.add('hidden');



    return () => {
      header.classList.remove('hidden');
      footer.classList.remove('hidden');
    };
  }, []);

  const ideasForWebsites = [
    'Онлайн-магазин спортивной одежды',
    'Портфолио фотографа',
    'Блог о путешествиях и приключениях',
    'Сайт для ресторана/кафе с онлайн-бронированием',
    'Платформа для онлайн-обучения и курсов',
    'Магазин органических продуктов и здорового питания',
    'Сайт медицинской клиники с записью на прием',
    'Портал для продажи и аренды недвижимости',
    'Блог о кино и сериалах',
    'Онлайн-журнал о моде и стиле',
    'Сайт по обзору техники и гаджетов',
    'Платформа для бронирования билетов на мероприятия',
    'Интернет-магазин товаров для дома и интерьера',
    'Сайт для обмена языковыми навыками',
    'Онлайн-магазин игрушек и товаров для детей',
    'Портал для обмена кулинарными рецептами'
  ];

  const [generatedIdeas, setGeneratedIdeas] = useState([]);

  const generateIdeas = () => {
    const shuffledIdeas = ideasForWebsites.sort(() => 0.5 - Math.random());
    const selectedIdeas = shuffledIdeas.slice(0, 3);
    setGeneratedIdeas(selectedIdeas);
  };

  let msg = useRef(null);

  const showMsg = () => {
    msg.current.classList.add('active')
    setTimeout(() => {
      msg.current.classList.remove('active')
    }, 4000)
  
  }

  return (
    <div className='admin flex text-white'>
      {/* AFRE CLICK MSG */}
      <div ref={msg} className='aftercl-msg flex flex-col'>
        <div className='bg-white text-black p-4 rounded-md dialog'>
         Выбор как обычно хуйня) Но я запомнил
        </div>
        <div className='flex justify-end mt-4'>
          <img className='max-w-[130px]' src='https://static.vecteezy.com/system/resources/previews/027/517/727/original/pixel-art-cartoon-pig-character-png.png' />
        </div>
      </div>

      <div className='w-[300px] bg-[#131313] h-[100vh] p-8 flex flex-col gap-8'>

        <div className='flex justify-center items-center gap-2'>
          <img className='w-[100px]' src={logo} />
          <span className='text-lg font-light'>
            WORKSPACE
          </span>
        </div>

        <div className='border-b-[2px] border-[#343434] pb-2'>
          <span className='text-sm font-light'>
            Home
          </span>
          <div className='mt-4 rounded-md flex gap-2 items-center p-3 hover:bg-[#343434] cursor-pointer'>
            <HomeIcon className="h-6 w-6 text-white" />
            Главная
          </div>
        </div>

        <div className='border-b-[2px] border-[#343434] pb-2'>
          <span className='text-sm font-light'>
            Learning
          </span>
          <div className='mt-4 rounded-md flex gap-2 items-center p-3 hover:bg-[#343434] cursor-pointer'>
            <LightBulbIcon className="h-6 w-6 text-white" />
            Темы для дизайна
          </div>
        </div>

        <div className='border-b-[2px] border-[#343434] pb-2'>
          <span className='text-sm font-light'>
            Website
          </span>
          <div className='mt-4 rounded-md flex gap-2 items-center p-3 hover:bg-[#343434] cursor-pointer'>
            <BookOpenIcon className="h-6 w-6 text-white" />
            Cтатьи
          </div>
          <div className=' rounded-md flex gap-2 items-center p-3 hover:bg-[#343434] cursor-pointer'>
            <BriefcaseIcon className="h-6 w-6 text-white" />
            Портфолио
          </div>
        </div>

        <div className='border-b-[2px] border-[#343434] pb-2'>
          <span className='text-sm font-light'>
            Tools
          </span>
          <div className='mt-4 rounded-md flex gap-2 items-center p-3 hover:bg-[#343434] cursor-pointer'>
            <CpuChipIcon className="h-6 w-6 text-white" />
            AI Tools
          </div>
          <div className='rounded-md flex gap-2 items-center p-3 hover:bg-[#343434] cursor-pointer'>
            <SunIcon className="h-6 w-6 text-white" />
            Тепловая карта
          </div>
          <div className='rounded-md flex gap-2 items-center p-3 hover:bg-[#343434] cursor-pointer'>
            <Cog6ToothIcon className="h-6 w-6 text-white" />
            Библиотеки
          </div>
        </div>


      </div>
      <div className='w-4/5 flex justify-center items-center'>
        <div className='flex flex-col items-center'>
          <button className='main-button' onClick={generateIdeas}>
            Generate
          </button>

          <div className='flex gap-5 mt-4'>
            {generatedIdeas.length > 0 ? (
              generatedIdeas.map((idea, index) => (
                <div onClick={showMsg} className='bg-[#343434] border-[1px] border-transparent hover:border-white text-white p-4 rounded-md cursor-pointer' key={index}>
                  {idea}
                </div>
              ))
            ) : (
              <div className='flex flex-col'>
                <div className='bg-white text-black p-4 rounded-md dialog'>
                  Че смотришь, нажимай давай
                </div>
                <div className='flex justify-end mt-4'>
                  <img className='max-w-[130px]' src='https://static.vecteezy.com/system/resources/previews/027/517/727/original/pixel-art-cartoon-pig-character-png.png' />
                </div>
              </div>


            )}



          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;